import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
var firebaseConfig = {
  apiKey: "AIzaSyD2fZGYCrQ68U7OJS7XlqR-qPG_i6C0x0A",
  authDomain: "organickle-store.firebaseapp.com",
  databaseURL: "https://organickle-store.firebaseio.com",
  projectId: "organickle-store",
  storageBucket: "organickle-store.appspot.com",
  messagingSenderId: "452927837460",
  appId: "1:452927837460:web:e1df7acf0387addc1a5e01",
};
// Initialize Firebase

export const myFirebase = firebase.initializeApp(firebaseConfig);
const baseDb = myFirebase.firestore();
export const firebase1 = firebase;
export const db = baseDb;
export const storage = firebase.storage();
