import React from "react";

const StatiSection = ({ title, desc, type, subType = "", children }) => {
  return (
    <section>
      <div className={type}>
        <div className={subType}>
          <h2>{title}</h2>
          <p>{desc}</p>
          {children}
        </div>
      </div>
    </section>
  );
};

export default StatiSection;
