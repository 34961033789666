import React from "react";
import { Carousel, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const SeeAllProducts = ({ items }) => {
  return (
    <section className="height-400">
      <div className="sec-1-cover">
        <div className="sec-1">
          <div className="slide-cover">
            <div className="slides">
              <Carousel controls={false} indicators={false}>
                {items.map((item, index) => (
                  <Carousel.Item key={index}>
                    <div className="view w-100">
                      <div>
                        <img src={item.imgSrc} width="100%" />
                      </div>
                      <Carousel.Caption>
                        <h1>{item.title}</h1>
                        <h2>{item.caption}</h2>
                        <Link to="/products/all">
                          <Button variant="outline-light">
                            See All Products
                          </Button>
                        </Link>
                      </Carousel.Caption>
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SeeAllProducts;
