import {
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_FAILURE,
  GET_PRODUCTS_SUCCESS,
  // SET_DETAIL_PRODUCT,
  // SET_CURRENT_BUY_PRODUCT,
  // SET_CART,
  // GET_CART_PRODUCTS_SUCCESS,
  // SEARCH_REQUEST,
  // SET_SEARCH_PRODUCTS,
  // SEARCH_ERROR,
  // SET_FINAL_BUY_PRODUCTS
  ADD_PRODUCT_TO_CART,
  REMOVE_ITEM,
  SET_CART,
  ADDING_PRODUCT,
  ADDING_QUANTITY,
} from "../actions/products";

const initialState = {
  loading: false,
  currentlyAdding: null,
  cart: [],
  cartCount: 0,
  amount: 0,
  products: [],
  // localCartProducts: null,
  // currentDetailProduct: null,
  // currentBuyProduct: null,
  // searchLoading: false,
  // searchText: '',
  // finalProducts: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CART:
      let total = 0;
      let totalAmount = 0;
      action.cart.map((item) => {
        total += parseInt(item.quantity);
        totalAmount += parseInt(item.price) * parseInt(item.quantity);
      });
      console.log(action.cart);
      return {
        ...state,
        cart: action.cart,
        cartCount: total,
        loading: false,
        currentlyAdding: null,
        amount: totalAmount,
      };
    case ADDING_PRODUCT:
      return {
        ...state,
        loading: true,
        currentlyAdding: action.productId,
      };

    case ADDING_QUANTITY:
      return {
        ...state,
        loading: true,
      };
    // case ADD_PRODUCT_TO_CART:
    //   let newCart = [...state.cart];
    //   let product = newCart.find(
    //     (product, index) =>
    //       product.id.toString() === action.product.id.toString()
    //   );
    //   if (product === null || product === undefined) {
    //     newCart.push(action.product);
    //   } else {
    //     newCart = newCart.map((product, index) =>
    //       product.id.toString() === action.product.id.toString()
    //         ? action.product
    //         : product
    //     );
    //   }

    //   let newTotal = state.cartCount;
    //   let totalAmount = state.amount;

    //   for (let i = 0; i < newCart.length; i++) {
    //     totalAmount += parseInt(newCart[i].price);
    //   }
    //   newTotal += parseInt(action.product.quantity);
    //   return {
    //     ...state,
    //     cart: newCart,
    //     cartCount: newTotal,
    //     amount: totalAmount,
    //   };

    case REMOVE_ITEM:
      let carts = [...state.cart];
      let reqIndex = -1;
      carts.find((product, index) => {
        if (product.id.toString() === action.product.id.toString()) {
          reqIndex = index;
        }
      });
      carts.splice(reqIndex, 1);

      let newTotal1;
      let totalAmount1 = 0;

      for (let i = 0; i < carts.length; i++) {
        totalAmount1 += parseInt(carts[i].price);
        newTotal1 += parseInt(carts[i].quantity);
      }

      return {
        ...state,
        cart: carts,
        cartCount: newTotal1,
        amount: totalAmount1,
      };
    //fetch products
    case GET_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.products,
      };

    // //SET cart
    // case SET_CART:
    //     return {
    //         ...state,
    //         cart: action.cart
    //     }
    // case GET_CART_PRODUCTS_SUCCESS:
    //     return {
    //         ...state,
    //         localCartProducts: action.products,
    //         loading: false
    //     }

    // //GET current details product
    // case SET_DETAIL_PRODUCT:
    //     return {
    //         ...state,
    //         currentDetailProduct: action.product,
    //         loading: false
    //     }

    // //GET current buy product
    // case SET_CURRENT_BUY_PRODUCT: {
    //     return {
    //         ...state,
    //         currentBuyProduct: action.product,
    //         loading: false
    //     }
    // }

    // //search
    // case SEARCH_REQUEST: {
    //     return {
    //         ...state,
    //         searchLoading: true
    //     }
    // }
    // case SET_SEARCH_PRODUCTS: {
    //     return {
    //         ...state,
    //         searchProducts: action.payload.searchProducts,
    //         searchText: action.payload.searchText,
    //         searchLoading: false
    //     }
    // }

    // case SEARCH_ERROR: {
    //     return {
    //         ...state,
    //         searchLoading: false
    //     }
    // }

    // //set final buy products
    // case SET_FINAL_BUY_PRODUCTS: {
    //     return {
    //         ...state,
    //         finalProducts: action.products
    //     }
    // }

    default:
      return state;
  }
};

export default reducer;
