import { db, storage } from "../../firebase/firebase";
// import store from "../configureStore";

export const ADD_PRODUCT_TO_CART = "ADD_PRODUCT_TO_CART";
export const REMOVE_ITEM = "REMOVE_ITEM";
export const SET_CART = "SET_CART";
export const ADDING_PRODUCT = "ADDING_PRODUCT";
export const MINUS_ITEM_QUANTITY = "MINUS_ITEM_QUANTITY";
export const ADD_ITEM_QUANTITY = "ADD_ITEM_QUANTITY";
export const ADDING_QUANTITY = "ADDING_QUANTITY";

const addingProduct = (productId) => {
  return {
    type: ADDING_PRODUCT,
    productId,
  };
};

export const setCart = (cart) => {
  return {
    type: SET_CART,
    cart,
  };
};

export const removeItem = (product) => {
  return {
    type: REMOVE_ITEM,
    product,
  };
};
export const addingQuantity = () => {
  return {
    type: ADDING_QUANTITY,
  };
};

const getUserUid = () => {
  let useruid;
  // if (store().getState().auth.user.uid) {
  //   useruid = store().getState().auth.user.uid;
  // } else if (store().getState().auth.user.user) {
  //   useruid = store().getState().auth.user.user.uid;
  // } else {
  if (localStorage.getItem("useruid")) {
    useruid = JSON.parse(localStorage.getItem("useruid"));
  } else {
    useruid = "";
  }
  // }
  return useruid;
};

export const handleMinusItem = (product) => (dispatch) => {
  dispatch(addItemQuantity(product, "minus"));
};

export const handleAddItem = (product) => (dispatch) => {
  dispatch(addItemQuantity(product, "add"));
};

export const removeProduct = (product) => (dispatch, getState) => {
  dispatch(addingProduct(product.id));
  if (getUserUid() === null || getUserUid === undefined) {
    let cart = [...getState().products.cart];
    let reqIndex = -1;
    let alreadyExists = !!cart.find((produce, index) => {
      if (produce.id.toString() == product.id.toString()) {
        reqIndex = index;
      }
      return produce.id.toString() == product.id.toString();
    });
    if (alreadyExists) {
      cart.splice(reqIndex, 1);
    }
    dispatch(setCart(cart));
  } else {
    db.collection("users")
      .doc(getUserUid())
      .get()
      .then((doc) => {
        if (doc.exists) {
          let cart = [...doc.data().cart];
          let reqIndex = -1;
          console.log(doc.data(), cart);
          let alreadyExists = !!cart.find((produce, index) => {
            if (produce.id.toString() == product.id.toString()) {
              reqIndex = index;
            }
            return produce.id.toString() == product.id.toString();
          });

          console.log(alreadyExists);
          if (alreadyExists) {
            cart.splice(reqIndex, 1);
          }
          db.collection("users")
            .doc(getUserUid())
            .update({
              cart: cart,
            })
            .then(() => {
              dispatch(setCart(cart));
            });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      });
  }
};

export const addItemQuantity = (product, type) => (dispatch, getState) => {
  dispatch(addingQuantity());
  if (getUserUid() === null || getUserUid === undefined) {
    let cart = [...getState().products.cart];
    let newCart = cart.map((produce, index) => {
      if (produce.id.toString() == product.id.toString()) {
        if (type === "add") produce.quantity += 1;
        else {
          if (produce.quantity > 0) {
            produce.quantity -= 1;
          }
        }
      }
      return produce;
    });

    dispatch(setCart(newCart));
  } else {
    db.collection("users")
      .doc(getUserUid())
      .get()
      .then((doc) => {
        if (doc.exists) {
          let cart = [...doc.data().cart];
          let newCart = cart.map((produce, index) => {
            if (produce.id.toString() == product.id.toString()) {
              if (type === "add") produce.quantity += 1;
              else {
                if (produce.quantity > 0) {
                  produce.quantity -= 1;
                }
              }
            }
            return produce;
          });

          db.collection("users")
            .doc(getUserUid())
            .update({
              cart: newCart,
            })
            .then(() => {
              dispatch(setCart(newCart));
            });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      });
  }
};

export const addProduct = (product) => (dispatch, getState) => {
  dispatch(addingProduct(product.id));
  if (!getUserUid()) {
    let cart = [...getState().products.cart];
    let reqIndex = -1;
    let alreadyExists = !!cart.find((produce, index) => {
      if (produce.id.toString() == product.id.toString()) {
        reqIndex = index;
      }
      return produce.id.toString() == product.id.toString();
    });
    if (alreadyExists) {
      cart.splice(reqIndex, 1);
    } else {
      cart.push(product);
    }
    dispatch(setCart(cart));
  } else {
    db.collection("users")
      .doc(getUserUid())
      .get()
      .then((doc) => {
        if (doc.exists) {
          let cart = [...doc.data().cart];
          let reqIndex = -1;
          console.log(doc.data(), cart);
          let alreadyExists = !!cart.find((produce, index) => {
            if (produce.id.toString() == product.id.toString()) {
              reqIndex = index;
            }
            return produce.id.toString() == product.id.toString();
          });

          console.log(alreadyExists);
          if (alreadyExists) {
            cart.splice(reqIndex, 1);
          } else {
            cart.push(product);
          }
          db.collection("users")
            .doc(getUserUid())
            .update({
              cart: cart,
            })
            .then(() => {
              dispatch(setCart(cart));
            });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      });
  }
};

export const resetCart = () => (dispatch, getState) => {
  db.collection("users")
    .doc(getUserUid())
    .update({
      cart: [],
    })
    .then(() => {
      dispatch(setCart([]));
    });
};

export const GET_PRODUCTS_REQUEST = "GET_PRODUCTS_REQUEST";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_FAILURE = "GET_PRODUCTS_FAILURE";

const requestProducts = () => {
  return {
    type: GET_PRODUCTS_REQUEST,
  };
};

const receiveProducts = (products) => {
  return {
    type: GET_PRODUCTS_SUCCESS,
    products,
  };
};

const productsError = (error) => {
  return {
    type: GET_PRODUCTS_FAILURE,
    error,
  };
};

export const fetchProducts = () => async (dispatch) => {
  dispatch(requestProducts());
  const products = [];
  let snapshots = await db.collection("products").get();
  for (const doc of snapshots.docs) {
    let url = await storage
      .ref(`pickles/${doc.data().imgSrc}`)
      .getDownloadURL();
    products.push({
      ...doc.data(),
      ["imgSrc"]: url,
      ["firebase-id"]: doc.id,
    });
  }
  dispatch(receiveProducts(products));

  // .catch((err) => {
  //   console.log(err);
  //   dispatch(productsError(err.message));
  // });
};
// import { db } from "../../firebase/firebase";

// export const SET_DETAIL_PRODUCT = "SET_DETAIL_PRODUCT";
// export const SET_CURRENT_BUY_PRODUCT = "SET_CURRENT_BUY_PRODUCT";

// export const SET_CART = "SET_CART";
// export const GET_CART_PRODUCTS_SUCCESS = "GET_CART_PRODUCTS_SUCCESS";

// export const SEARCH_REQUEST = "SEARCH_REQUEST";
// export const SET_SEARCH_PRODUCTS = "SET_SEARCH_PRODUCTS";
// export const SEARCH_ERROR = "SEARCH_ERROR";

// export const SET_FINAL_BUY_PRODUCTS = "SET_FINAL_BUY_PRODUCTS";

// const receiveCartProducts = (products) => {
//   return {
//     type: GET_CART_PRODUCTS_SUCCESS,
//     products,
//   };
// };

// const receiveCurrentBuyProduct = (product) => {
//   return {
//     type: SET_CURRENT_BUY_PRODUCT,
//     product,
//   };
// };

// const receiveDetailProduct = (product) => {
//   return {
//     type: SET_DETAIL_PRODUCT,
//     product,
//   };
// };

// const requestSearch = () => {
//   return {
//     type: SEARCH_REQUEST,
//   };
// };

// const setSearchResults = (searchProducts, searchText) => {
//   return {
//     type: SET_SEARCH_PRODUCTS,
//     payload: {
//       searchProducts,
//       searchText,
//     },
//   };
// };

// const searchError = () => {
//   return {
//     type: SEARCH_ERROR,
//   };
// };

// const setFinalBuyProducts = (products) => {
//   return {
//     type: SET_FINAL_BUY_PRODUCTS,
//     products,
//   };
// };

// export const getProducts = () => (dispatch) => {
//   dispatch(requestProducts());
//   const products = [];
//   db.collection("products")
//     .get()
//     .then((snapshot) => {
//       snapshot.forEach((doc) => {
//         products.push({ ...doc.data(), ["id"]: doc.id });
//       });
//     })
//     .then(() => {
//       dispatch(receiveProducts(products));
//     })
//     .catch((err) => {
//       console.log(err);
//       dispatch(productsError());
//     });
// };

// export const getCartProducts = (cart) => (dispatch) => {
//   dispatch(requestProducts());
//   const products = [];
//   console.log("this is cart", cart);
//   if (cart.length === 0) {
//     dispatch(receiveCartProducts(products));
//   } else {
//     cart.forEach((dataId, index, arr) => {
//       db.collection("products")
//         .doc(dataId)
//         .get()
//         .then((res) => {
//           products.push({ ...res.data(), ["id"]: dataId });
//           if (index === arr.length - 1) {
//             dispatch(receiveCartProducts(products));
//           }
//         })
//         .catch((err) => {
//           dispatch(productsError());
//         });
//     });
//   }
// };

// export const getCurrentBuyProduct = (productId) => (dispatch) => {
//   dispatch(requestProducts());
//   console.log(productId);
//   db.collection("products")
//     .doc(productId)
//     .get()
//     .then((res) => {
//       dispatch(receiveCurrentBuyProduct({ ...res.data(), ["id"]: productId }));
//     })
//     .catch((err) => {
//       dispatch(productsError());
//     });
// };

// export const getDetailProduct = (productId) => (dispatch) => {
//   console.log("received product id", productId);
//   dispatch(requestProducts());
//   console.log(productId);
//   db.collection("products")
//     .doc(productId)
//     .get()
//     .then((res) => {
//       dispatch(receiveDetailProduct({ ...res.data(), ["id"]: productId }));
//     })
//     .catch((err) => {
//       dispatch(productsError());
//     });
// };

// export const getSearchResults = (searchText) => (dispatch) => {
//   dispatch(requestSearch());
//   const productsRef = db.collection("products");
//   productsRef
//     .where("product_title_index", "array-contains", searchText.toUpperCase())
//     .get()
//     .then((snapshot) => {
//       let newArr = [];

//       snapshot.forEach((doc) => newArr.push({ ...doc.data(), ["id"]: doc.id }));
//       dispatch(setSearchResults(newArr, searchText));
//     })
//     .catch((err) => {
//       dispatch(searchError());
//     });
// };

// export const setFinalProducts = (products) => (dispatch) => {
//   dispatch(setFinalBuyProducts(products));
// };
