import React from "react";
import { BottomCard } from "./BottomCard/";

const BottomCards = ({ items }) => {
  return (
    <section>
      <div className="sec-4-cover">
        <div className="sec-4">
          <div className="service-list">
            <ul>
              {items &&
                items.map((item, index) => (
                  <BottomCard
                    key={index}
                    imgSrc={item.imgSrc}
                    title={item.title}
                    desc={item.desc}
                  />
                ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BottomCards;
