import React from "react";
import { Carousel } from "react-bootstrap";
import "./AuthContainer.css";

const AuthContainer = ({ items, children }) => {
  return (
    <div className="auth-container-root">
      <div className="auth-container-carousel">
        <Carousel controls={false} indicators={false}>
          {items.map((item, index) => (
            <Carousel.Item key={index}>
              <div className="view w-100">
                <div>
                  <img src={item.imgSrc} width="100%" />
                </div>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
      <div className="auth-container-form">{children}</div>
    </div>
  );
};

export default AuthContainer;
