import React from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { getDetailProduct, addProduct } from "../../store/actions";

const ProductCard = ({
  products,
  isAuthenticated,
  addProductConnect,
  cart,
  loading,
  currentlyAdding,
  // getDetailProductConnect,
}) => {
  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname;

  const isPresentInCart = (product) =>
    !!cart.find((produce) => produce.id.toString() === product.id.toString());

  const handleAddToCart = (product) => {
    if (!isAuthenticated) {
      history.push({
        pathname: "/login",
        state: { pathname },
      });
    } else {
        addProductConnect(product);
    }
  };

  const handleBuyNow = () => {
    // redirect to login if not logged in
    if (!isAuthenticated) {
      history.push({
        pathname: "/login",
        state: { pathname },
      });
    } else {
      history.push("/cart");
    }
  };

  return (
    <section>
      {products &&
        products.map(({ id, link, title, imgSrc, price, desc }, index, arr) => (
          <div className="card" key={index}>
            <Link to={`/products/product/${id}`}>
              <img alt={title} width="100%" src={imgSrc} />
              <h1>{title}</h1>
            </Link>
            <p className="price">₹ {price}</p>
            <p className="product-disc">{desc}</p>
            <div className="product-actions">
              <Button
                variant={isPresentInCart(arr[index]) ? "dark" : "outline-dark"}
                onClick={() => handleAddToCart(arr[index])}
                disabled={loading && currentlyAdding == id}
              >
                {loading && currentlyAdding == id ? (
                  <FontAwesomeIcon icon={faSpinner} spin={true} />
                ) : (
                  <FontAwesomeIcon icon={faCartPlus} />
                )}
                &nbsp;
                {isPresentInCart(arr[index]) ? "Remove" : "Add to Cart"}
              </Button>
              {isPresentInCart(arr[index]) && (
                <Button
                  style={{ marginLeft: "2px" }}
                  variant={
                    isPresentInCart(arr[index]) ? "outline-dark" : "dark"
                  }
                  onClick={() => {
                    history.push("/cart");
                  }}
                >
                  View Cart
                </Button>
              )}
            </div>
          </div>
        ))}
    </section>
  );
};

const mapStateToProps = (state) => {
  const { isAuthenticated } = state.auth;
  const { cart, loading, currentlyAdding } = state.products;
  return {
    isAuthenticated,
    cart,
    loading,
    currentlyAdding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addProductConnect: (product) => dispatch(addProduct(product)),
    // getDetailProductConnect: (productId) =>
    //   dispatch(getDetailProduct(productId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);
