import React from "react";
import classes from "./Spinner.module.css";

const Spinner = ({ color }) => {
  return (
    <div className={classes.container}>
      <div className={classes.spinner}>
        <div
          className={classes.rect1}
          style={{ background: color ? color : "black" }}
        ></div>
        <div
          className={classes.rect2}
          style={{ background: color ? color : "black" }}
        ></div>
        <div
          className={classes.rect3}
          style={{ background: color ? color : "black" }}
        ></div>
        <div
          className={classes.rect4}
          style={{ background: color ? color : "black" }}
        ></div>
        <div
          className={classes.rect5}
          style={{ background: color ? color : "black" }}
        ></div>
      </div>
    </div>
  );
};

export default Spinner;
