import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { useRef } from "react";
import { useEffect } from "react";

const NavBar = ({ links, isAuthenticated, cartCount }) => {
  const history = useHistory();
  const menu = useRef();
  const menuBtn = useRef();
  const cartIcon = useRef();

  const handleCartClick = () => history.push("/cart");

  const handleMenuItemClick = () => {
    menu.current.classList.add("height-0");
    menuBtn.current.checked = false;
  };
  const handleNavIconClick = () => menu.current.classList.remove("height-0");
  const handleLoginClick = () => history.push("/login");

  return (
    <header className="header topbar">
      <Link className="logo" to="/">
        <img
          alt="Oragnickle Logo"
          src="https://drive.google.com/uc?export=view&amp;id=1j6ys5Ggf_Q8fDsUt5arFBV7H6GHyEY7u"
        />
      </Link>

      <input className="menu-btn" id="menu-btn" type="checkbox" ref={menuBtn} />
      <label
        className="menu-icon"
        htmlFor="menu-btn"
        onClick={handleNavIconClick}
      >
        <span className="navicon"></span>
      </label>
      <span onClick={handleCartClick} className={"cart-icon"} ref={cartIcon}>
        <FontAwesomeIcon
          icon={faCartPlus}
          size="1x"
          style={{ color: "white" }}
        />
        <span className={"cart-number"}>{cartCount}</span>
      </span>
      <ul className="menu topmenu" ref={menu}>
        {links &&
          links.map((link, index) => (
            <li className="nav-item" key={index}>
              {link.link &&
                (link.link === "/orders" ? (
                  isAuthenticated ? (
                    <Link
                      className="nav-link"
                      to={link.link}
                      onClick={handleMenuItemClick}
                    >
                      {link.title}&nbsp; {link.children}
                    </Link>
                  ) : null
                ) : (
                  <Link
                    className="nav-link"
                    to={link.link}
                    onClick={handleMenuItemClick}
                  >
                    {link.title}&nbsp; {link.children}
                  </Link>
                ))}
              {link.onClick ? (
                isAuthenticated ? (
                  <Button
                    variant="light"
                    onClick={() => {
                      link.onClick();
                      handleMenuItemClick();
                    }}
                  >
                    {link.title}
                  </Button>
                ) : (
                  <Button
                    variant="light"
                    onClick={() => {
                      handleLoginClick();
                      handleMenuItemClick();
                    }}
                  >
                    Login
                  </Button>
                )
              ) : null}
            </li>
          ))}
      </ul>
    </header>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(NavBar);
