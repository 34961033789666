import React, { useEffect } from "react";
import { BrowserRouter, useHistory } from "react-router-dom";
import { connect } from "react-redux";

import "./App.css";
import Root from "./Root";
import { NavBar, Footer } from "./app";
import { logoutUser, verifyAuth, fetchProducts } from "./store/actions";
import { Badge } from "react-bootstrap";
import { db } from "./firebase/firebase";

const App = ({ cartCount, logoutUserConnect, fetchProductsConnect }) => {
  const history = useHistory();

  const navLinks = [
    {
      link: `/`,
      title: `Home`,
      children: <span className="sr-only">(current)</span>,
    },
    {
      link: `/about`,
      title: `About`,
    },
    {
      link: `/products/all`,
      title: `Our Products`,
    },
    {
      link: `/orders`,
      title: `My Orders`,
    },
    {
      link: `/cart`,
      title: `My Cart`,
      children: <Badge variant="danger"> {cartCount || 0} </Badge>,
    },
    {
      link: `/contact`,
      title: `Contact`,
    },
    {
      onClick: () => {
        history.replace("/");
        logoutUserConnect();
      },
      title: `Logout`,
    },
  ];

  useEffect(() => {
    // Uncomment to add products
    // products.forEach((product) => {
    //   db.collection("products")
    //     .add(product)
    //     .then((res) => {
    //       console.log(res);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // });
    fetchProductsConnect();
  }, []);
  return (
    <div className="App">
      <NavBar links={navLinks} cartCount={cartCount} />
      <Root />
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    cartCount: state.products.cartCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutUserConnect: () => dispatch(logoutUser()),
    fetchProductsConnect: () => dispatch(fetchProducts()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
