import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import "./Footer.css";

export default function Footer() {
  return (
    <>
      <div className="clearfix"></div>
      <div className="footer" style={{ color: "white" }}>
        <div className="contain">
          <div className="col"></div>
          <div className="col">
            <h1>Company</h1>
            <ul>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/contact">Get in touch</Link>
              </li>
            </ul>
          </div>
          <div className="col">
            <h1>Products</h1>
            <ul>
              <li>
                <Link className="nav-link" to="/products/all">
                  Products
                </Link>
              </li>
            </ul>
          </div>
          <div className="col">
            <h1>Support</h1>
            <ul>
              <li>
                <Link to="/contact">Get in touch</Link>
              </li>
            </ul>
          </div>
          <div className="col social">
            <h1>Social</h1>
            <ul>
              <li>
                <a target="_blank" href="https://www.instagram.com/organickle/">
                  <FontAwesomeIcon icon={faInstagram} />{" "}
                </a>
              </li>
              <li>
                <a target="_blank" href="https://www.facebook.com/organickle">
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
              </li>
            </ul>
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
    </>
  );
}
