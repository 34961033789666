import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

import Spinner from "./common/Spinner/Spinner";
import ProtectedRoute from "./app/ProtectedRoute/ProtectedRoute";

const Address = lazy(() => import("./Address/AddressForm"));
const Cart = lazy(() => import("./Cart/Cart"));
const Orders = lazy(() => import("./Orders/Orders"));

const Home = lazy(() => import("./home/HomeContainer"));
const AllProducts = lazy(() => import("./products/AllProductsContainer"));
const About = lazy(() => import("./about/AboutContainer"));
const Contact = lazy(() => import("./contact/ContactContainer"));
const Login = lazy(() => import("./login/LoginComponent"));
const ProductDetails = lazy(() => import("./ProductDetails/ProductDetails"));

const Root = (props) => {
  return (
    <Switch>
      <Suspense fallback={<Spinner />}>
        <ProtectedRoute 
          exact
          key="address"
          path="/address"
          isAuthenticated={props.isAuthenticated}
          component={Address}
        />
        <Route exact key="cart" path="/cart" component={Cart} />
        <ProtectedRoute
          exact
          key="orders"
          path="/orders"
          isAuthenticated={props.isAuthenticated}
          component={Orders}
        />
        <Route exact key="home" path="/" component={Home} />
        <Route
          exact
          key="allProducts"
          path="/products/all"
          component={AllProducts}
        />
        <Route
          exact
          key="product"
          path="/products/product/:id"
          component={ProductDetails}
        />
        <Route exact key="about" path="/about" component={About} />
        <Route exact key="contact" path="/contact" component={Contact} />
        <Route exact key="login" path="/login" component={Login} />
      </Suspense>
    </Switch>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isVerifying: state.auth.isVerifying,
  };
};

export default connect(mapStateToProps)(Root);
