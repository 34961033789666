import { myFirebase } from "../../firebase/firebase";
import { db } from "../../firebase/firebase";
import { setCart } from "./products";

//login
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

//logout
export const LOGOUT_REQUEST = "LOmyFirebaseGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

//verify auth
export const VERIFY_REQUEST = "VERIFY_REQUEST";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";

//signup
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_ERROR = "SIGNUP_ERROR";

const requestLogin = () => {
  return {
    type: LOGIN_REQUEST,
  };
};

const receiveLogin = (user) => {
  return {
    type: LOGIN_SUCCESS,
    user,
  };
};

const loginError = () => {
  return {
    type: LOGIN_FAILURE,
  };
};

const requestLogout = () => {
  return {
    type: LOGOUT_REQUEST,
  };
};

const receiveLogout = () => {
  return {
    type: LOGOUT_SUCCESS,
  };
};

const logoutError = () => {
  return {
    type: LOGOUT_FAILURE,
  };
};

const verifyRequest = () => {
  return {
    type: VERIFY_REQUEST,
  };
};

const verifySuccess = () => {
  return {
    type: VERIFY_SUCCESS,
  };
};

export const signUpSuccess = (user) => {
  return {
    type: SIGNUP_SUCCESS,
    user,
  };
};
const requestSignup = () => {
  return {
    type: SIGNUP_REQUEST,
  };
};
const signUpError = (signupErrMessage) => {
  return {
    type: SIGNUP_ERROR,
    signupErrMessage,
  };
};

export const setUserDetails = (user) => async (dispatch) => {
  if (user) {
    db.collection("users")
      .doc(user.uid)
      .get()
      .then((doc) => {
        dispatch(signUpSuccess(doc.data()));
        dispatch(setCart(doc.data().cart));
      })
      .catch((err) => {
        dispatch(signUpError(err.message));
      });
  }
};

//async actions for thunk
export const loginUser = () => (dispatch) => {
  dispatch(requestLogin());
  myFirebase
    .auth()
    .then((user) => {
      dispatch(receiveLogin(user));
    })
    .catch((error) => {
      dispatch(loginError(error.message));
    });
};

export const logoutUser = () => (dispatch) => {
  dispatch(requestLogout());

  myFirebase
    .auth()
    .signOut()
    .then(() => {
      localStorage.clear();
      dispatch(receiveLogout());
    })
    .catch((error) => {
      //Do something with the error if you want!
      dispatch(logoutError(error.message));
    });
};

export const verifyAuth = () => (dispatch) => {
  dispatch(verifyRequest());
  myFirebase.auth().onAuthStateChanged((user) => {
    if (user !== null) {
      dispatch(setUserDetails(user));
      dispatch(receiveLogin(user));
      localStorage.setItem("useruid", JSON.stringify(user.uid));
    }
  });
};

export const signupUser = (email, password, userDetails) => (dispatch) => {
  dispatch(requestSignup());
  myFirebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then((user) => {
      dispatch(setUserDetails(user, userDetails));
      localStorage.setItem("useruid", JSON.stringify(user.uid));
    })
    .catch((err) => {
      dispatch(signUpError(err.message));
    });
};
