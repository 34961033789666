import React from "react";

const BottomCard = ({ imgSrc, title, desc }) => (
  <li>
    <img src={imgSrc} />
    <h2>{title}</h2>
    <p>{desc}</p>
  </li>
);

export default BottomCard;
