import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import auth from "./auth";
import products from "./products";
import user from "./user";

const appReducer = combineReducers({ auth, products, user });
const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_SUCCESS") {
    storage.removeItem("persist:products");
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
